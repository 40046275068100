import React from 'react';
import './globals.css';
import Releases from './Releases.js';
import Intro from './Intro';
import Links from './Links.js';
import Dashboard from './Dashboard';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ReleasePage from './ReleasesPage';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap"; // <-- JS File

function App() {
  return (
    <Router>
      
      <Switch>
        <Route exact path="/" component={Intro}/>
        <Route exact path="/releases" component={Releases} />
        <Route exact path="/releases/:id" component={ReleasePage} />
        <Route exact path="/links" component={Links} />
        <Route exact path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
    )
}
    
export default App;