import React, {useState} from 'react';
import './releaseinfo.css';

function ReleaseInfo (props) {

    const release = props.release;
    const [isShown, setIsShown] = useState(false);
    const tracklist = release.tracklist;

    return (
        <div className="cuerpo_release">
          <div className='link_release'>
            <a href={release.smartlink} target="_blank" rel="noopener noreferrer" >
              <div className="portada_release" 
                  onMouseEnter= {() => setIsShown(true)}
                  onMouseLeave = {() => setIsShown(false)}>
                    <img className="imagen_release" src={release.img_portada} alt={release.titulo}  />
                    {isShown && (
                      <div className="tracklist_release">
                        {tracklist.map((song) => {
                          return release.tracklist != null ?
                            <h6 className="song_release" key={song}>{song}</h6> : null
                          })}
                      </div>
                    )}   
              </div>
            </a>
          </div>
          <div className='resume_release'>
            <div className='resume_release_up'>
              <h3 className='header_release'>{release.titulo}</h3>
              <h4 className='header_release'>{release.artista}</h4>
            </div>
            <div className='mobile_tracklist_release'>
              <h6 id='mobile_header_tracklist'>TRACKLIST</h6>
              {tracklist.map((song) => {
                return release.tracklist != null ?
                  <h6 className="mobile_song_release" key={song}>{song}</h6> : null
              })}
                </div>
            <div className='resume_release_down'>
              <h5 className='header_release'>{release.arte}</h5>
              <h5 className='header_release'>{release.produccion}</h5>
            </div>
          </div>
        </div>
    )
}

export default ReleaseInfo;