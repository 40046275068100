import Rios from './img/Portada_3rios.jpg';
import Stimolazione from './img/Portada_Stimolazione.gif';
import Abrazame from './img/Portada_Abrazame.jpg';
import Villancicos3 from './img/Portada_Villancicos3.jpg';
import Tren from './img/Portada_Trenamadrid.png';
import PromFest2020 from './img/Portada_PromFest2020.jpg';
import Pole from './img/Portada_Pole.jpg';
import PromFest2019_Madrid from './img/Portada_PromFest2019_Madrid.jpg';
import Villancicos2 from './img/Portada_Villancicos2.jpg';
import Directo from './img/Portada_Directoenmihabitacion.jpg';
import Producto from './img/portada_ProductoFinal.jpg';
import FutbolClub from './img/Portada_FutbolClub.jpg';
import Pincho from './img/Portada_PinchoTortilla.png';
import PromFest2019_Barcelona from './img/Portada_PromFest2019_Barcelona.jpg';
import Recuerdos from './img/portada_recuerdos.jpg'
import Villancicos1 from './img/portada_Villancicos1.png';
import Grandes from './img/portada_JuncalRivero.jpg';
import corazoncalzoncillo from './img/Portada_corazoncalzoncillo.png';
import K7 from './img/portada_K7.png';
import Bofirax from './img/portada_Bofirax.jpg';
import Pregunta from './img/Portada_Pregunta.gif';
import Wapa from './img/Portada_wapa.jpg';
import Dama from './img/Portada_dama.jpg';
import Wasap from './img/Portada_Wasap.jpg';
import Sobrevivire from './img/Portada_Sobrevivire.jpg';
import DecirAdios from './img/Portada_decirAdios.jpg';
import MiXico from './img/Portada_MiXico.jpg';
import MeAmaras from './img/Portada_meamarasmañana.jpg';
import RecuerdosXico from './img/Portada_recuerdosxico.jpg';
import CancionPop from './img/Portada_CancionPop.png';
import LagoasDream from './img/Portada_lagoas_dream.png';
import CallCenter from './img/Portada_CallCenter.png';
import Caraoque from './img/Portada_Caraoque.jpg';
import Tabata from './img/Portada_Tabata.jpg';
import CancionesApolo from './img/Portada_Lascancionesdeapolo.jpg';
import PongamosMadrid from './img/Portada_pongamos que hablo de madrid.png';
import Verde from './img/Portada_Verde.jpg';
import ToxicPop from './img/Portada_ToxicPop.jpg';
import CorazonContento from './img/Portada_CorazonContento.png';
import Piensoenmi from './img/Portada_Piensoenmi.jpg';
import TeInvitoFiesta from './img/Portada_Teinvitoami fiesta.png';
import Benimaclet from './img/Portada_Benimaclet.png';
import KaraBoi from './img/Portada_karaboi.jpg';
import Nocturno from './img/Portada_Nocturno.jpg';
import Notas from './img/portada_Notas.jpg'
import Loba from './img/Portada_Loba.jpg';
import Diegocentrista from './img/portada_diegocentrista.jpg';
import Primera from './img/portada_PrimeraVez.jpg';
import Villancicos4 from './img/portada_Villancicos4.png';
import Morirme_contigo from './img/Portada_Quiero_morirme_contigo.jpg'
import Metamorfosis from './img/portada_Metamorfosis.jpg';
import Corazon_partio from './img/Portada_CorazonPartio.jpg';
import Sugar_Mami from './img/Portada_Sugar_Mami.jpg';  
import No_se_nadar_remix from './img/Portada_no_se_nadar_remix.jpg';
import Fines_de_Semana from './img/Portada_fines_de_semana.jpg';
import Aire_y_Angeles from './img/Portada_Aire_y_Angeles.jpg';
import Parque_de_Atracciones from './img/Portada_Parque_de_Atracciones.jpg';
import MHYV from './img/Portada_MHYV.JPG';
import AVAQS from './img/Portada_MHYV.JPG';

export const releases = [
    {
        id: "58",
        titulo: 'MHYV',
        artista: "Lil Ella",
        img_portada: MHYV,
        ref: "MHYV",
        smartlink: "https://ditto.fm/myhyv",
        produccion: "Producido por Karma C",
        arte: "Portada de Miguel Vides",
        tracklist: ["La Primera Vez", "Hándicap", "Parque de Atracciones", "AVAQS?", "Las Ganas", "Dame tu Boca", "Al Vuelo", "MICOSITA", "Fines de Semana"]
    },
    {
        id: "57",
        titulo: 'AVAQS?',
        artista: "Lil Ella + TURIAN BOY",
        img_portada: AVAQS,
        ref: "AVAQS?",
        smartlink: "https://ditto.fm/avaqs",
        produccion: "Producido por TURIAN BOY",
        arte: "Portada de Miguel Vides",
        tracklist: ["AVAQS?"]
    },
    {
        id: "56",
        titulo: 'Parque de Atracciones',
        artista: "Lil Ella + pawoblo",
        img_portada: Parque_de_Atracciones,
        ref: "parque_de_atracciones",
        smartlink: "https://ditto.fm/lilella_pawoblo_parquede_",
        produccion: "Producido por marinadorciud",
        arte: "Portada de Miguel Vides",
        tracklist: ["Parque de Atracciones"]
    },
    {
        id: "55",
        titulo: 'Aire y Ángeles',
        artista: "9ckles",
        img_portada: Aire_y_Angeles,
        ref: "aire_y_angeles",
        smartlink: "https://ditto.fm/aire-y-angeles",
        produccion: "Producido por 9ckles",
        arte: "Portada de @elinstadeguti",
        tracklist: ["La hija de Juan Simón", "Aire y Ángeles", "No se", "Alyssa Hamilton"]
    },
    {
        id: "54",
        titulo: 'Fines de Semana',
        artista: "Lil Ella + Axelot",
        img_portada: Fines_de_Semana,
        ref: "fines_de_semana",
        smartlink: "https://ditto.fm/fines_de_semana-lil_ella",
        produccion: "Producido por marinadorciud, Karma C, Lil Ella, Axelot",
        arte: "Portada de Miguel Vides",
        tracklist: ["Fines de Semana"]
    }, 
    {
        id: "53",
        titulo: 'no se nadar (paco moreno remix)',
        artista: "krissia + Paco Moreno",
        img_portada: No_se_nadar_remix,
        ref: "no_se_nadar_remix",
        smartlink: "https://ditto.fm/no_se_nadar_paco_moreno_remix-krissia",
        produccion: "Producido por Paco Moreno",
        arte: "Portada de uri llobet",
        tracklist: ["no se nadar (paco moreno remix)"]
    }, 
    {
        id: "52",
        titulo: 'Sugar Mami',
        artista: "MILC",
        img_portada: Sugar_Mami,
        ref: "sugar_mami",
        smartlink: "https://ditto.fm/sugar_mami-milc",
        produccion: "Producido por MILC",
        arte: "Portada de @elinstadeguti",
        tracklist: ["Sugar Mami"]
    }, 
    {
        id: "51",
        titulo: 'Quiero morirme contigo',
        artista: "9ckles",
        img_portada: Morirme_contigo,
        ref: "morirme_contigo",
        smartlink: "https://ditto.fm/quiero_morirme_contigo-9ckles",
        produccion: "Producido por 9ckles y Fedra",
        arte: "Portada de @elinstadeguti",
        tracklist: ["Quiero morirme contigo"]
    },
    {
        id: "50",
        titulo: 'Villancicos Vol. IV',
        artista: "Varios Artistas",
        img_portada: Villancicos4,
        ref: "villancicos4",
        smartlink: "https://ditto.fm/villancicos_vol_ix",
        produccion: "Los cencerros - Adiós Amores - Amor Butano - Mona Rora - Carlota - Carlofrancesco Goldaniga - MILC - julia amor - Darko Morandini - Flavia Marsana, TURIAN BOY - Morreo - eurosanto, gewrl",
        arte: "Portada de @angelabacher",
        tracklist: ["Los Cencerrazos - Arre Borriquito", "Adiós Amores - Blanca Navidad", "Amor Butano - De la Navidad al Neoliberalismo", "Mona Rora - Gatatumba Tumba", "Carlota - Dátiles con Bacon", "Carlofrancesco Goldaniga - Vint-i-cinc de Desembre", "MILC - Nadie se acuerda de los camellos", "julia amor - frio contigo", "Darko Morandini - Burrito camino de", "Flavia Marsano, TURIAN BOY - Contigo", "Morreo - Carita Divina", "eurosanto, gewrl - Last Xmas"]
    },
    {
        id: "49",
        titulo: 'Diegocentrista',
        artista: "MILC",
        img_portada: Diegocentrista,
        ref: "diegocentrista",
        smartlink: "https://ditto.fm/diegocentrista-milc",
        produccion: "Producido por MILC",
        arte: "Portada de @carlosmonnto",
        tracklist: ["Diegocentrista"]
    },
    {
        id: "48",
        titulo: 'La Primera Vez',
        artista: "Lil Ella",
        img_portada: Primera,
        ref: "primera_vez",
        smartlink: "https://ffm.to/lilella_laprimeravez",
        produccion: "Producida por Karma C",
        arte: "Fotografía de Neelam Khan Vela y portada de Miguel Vides",
        tracklist: ["La Primera Vez"]
    },
    {
        id: "47",
        titulo: 'Loba',
        artista: "Amor Butano",
        img_portada: Loba,
        ref: "loba",
        smartlink: "https://ditto.fm/loba-amor_butano",
        produccion: "Producido por Diego Ferrando Devesa",
        arte: "Portada de @anicestudio_",
        tracklist: ["Loba"]
    },
    {
        id: "46",
        titulo: 'Notas Sensibles',
        artista: "Popin'love",
        img_portada: Notas,
        ref: "notas_sensibles",
        smartlink: "https://ditto.fm/notas_sensibles-popin_love",
        produccion: "Producido por Popin'love",
        arte: "Portada de Mark Yareham",
        tracklist: ["Toxic Pop", "Shibari", "Un corazón para 2", "Toke de Tokyo", "Inútil", "Natural", "Páralo tu k puedes", "Castillo Ideal", "La canción más bonita", "Crystal", "Trátame bien", "Pienso en mí ++", "Blacklist"]
    },
    {
        id: "45",
        titulo: 'Nocturno de las ovejas',
        artista: 'MILC',
        img_portada: Nocturno,
        ref: "nocturno",
        smartlink: "https://ditto.fm/nocturno_de_las_ovejas-milc",
        produccion: "producido por MILC",
        arte: "Portada de MILC",
        tracklist: ["Nocturno de las ovejas"]
    },
    {
        id: "44",
        titulo: 'La metamorfosis',
        artista: '9ckles',
        img_portada: Metamorfosis,
        ref: "metamorfosis",
        smartlink: "https://ditto.fm/la_metamorfosis-9ckles",
        produccion: "Producido por 9ckles",
        arte: "Portada de @elinstadeguti",
        tracklist: [ 'La metamorfosis de Kafka', 'La metamorfosis de Ovidio']
    },
    {
        id: "43",
        titulo: 'kara boi',
        artista: 'krissia',
        img_portada: KaraBoi,
        ref: "kara_boi",
        smartlink: "https://ditto.fm/kara_boi-krissia_feat_egarae",
        produccion: "producido por egaræ & roc montoriol",
        arte: "portada de uri llobet",
        tracklist: [ '3 rios', 'Ya mi mama me decia', 'verde', 'no se nadar', 'daiwal', 'malibu', 'fuentes de ortiz (emostrings mix)', 'ihateu', 'player' ]
    },
    {
        id: "42",
        titulo: 'Benimaclet',
        artista: 'Amor Butano',
        img_portada: Benimaclet,
        ref: 'benimaclet',
        smartlink: "https://ditto.fm/benimaclet-amor_butano",
        produccion: 'Producido por Diego Ferrando Devesa',
        arte: 'Portada de Laura Rigau y fotografías de Helena Garriga',
        tracklist: ["Entropía", "Redes", "Olimpiadas", "Butano y Amor"]
    },
    {
        id: "41",
        titulo: 'Te invito a mi fiesta',
        artista: 'Perdón',
        img_portada: TeInvitoFiesta,
        ref: 'invito_fiesta',
        smartlink: "https://ditto.fm/te_invito_a_mi_fiesta-perdon",
        produccion: "Producido por Perdón. El verano en el campo ha sido mezclada por Guille Solano. Qué bien lo pasamos cuando estamos juntas ha sido mezclada por Álvaro Sierra. La masterización la ha llevado a cabo Pedro Ruiz (Viva Belgrado)",
        arte: "Portada de Perdón",
        tracklist: ["Lo siento", "Otra vez", "12 de Abril (ya es de día)", "El verano en el campo", "Niña wapa", "Corazón contento", "Zorra"]
    },
    {
        id: "40",
        titulo: 'Pienso en mi ++',
        artista: "Popin'love",
        img_portada: Piensoenmi,
        ref: 'pienso_mi',
        smartlink: "https://ditto.fm/pienso_en_mi_mas_mas-popinlove",
        produccion: "Producido por Leftee",
        arte: "Portada de Mark Yareham",
        tracklist: ["Pienso en mi ++"]
    },
    {
        id: "39",
        titulo: 'Corazón Contento',
        artista: 'Perdón',
        img_portada: CorazonContento,
        ref: 'corazon_contento',
        smartlink: "https://li.sten.to/biyfg696",
        produccion: "Producido por Perdón",
        arte: "Portada de Perdón",
        tracklist: ["Corazón Contento"]
    },
    {
        id: "38",
        titulo: 'Toxic Pop',
        artista: "Popin'love",
        img_portada: ToxicPop,
        ref: 'toxic_pop',
        smartlink: "https://ditto.fm/toxic_pop-popinlove",
        produccion: "Compuesta por el colectivo Toxic Pop y producida por Popin'love",
        arte: "Portada de Mark Yareham",
        tracklist: ["Toxic Pop"]
    },
    {
        id: "37",
        titulo: 'Verde',
        artista: 'krissia',
        img_portada: Verde,
        ref: "verde",
        smartlink: "https://ditto.fm/verde-krissia_feat_egarae",
        produccion: "producido por egaræ & roc montoriol",
        arte: "Portada de Stephen Please",
        tracklist: ["Verde"]
    },
    {
        id: "36",
        titulo: 'Pongamos que hablo de Madrid',
        artista: 'Ciberchico',
        img_portada: PongamosMadrid,
        ref: 'pongamos_madrid',
        smartlink: "https://ditto.fm/pongamos_que_hablo_de_madrid-ciberchico",
        produccion: "Producido por Ciberchico",
        arte: "Portada de Ciberchico",
        tracklist: ["Pongamos que hablo de Madrid"]
    },
    {
        id: "35",
        titulo: 'Las canciones de Apolo',
        artista: '9ckles',
        img_portada: CancionesApolo,
        ref: 'canciones_apolo',
        smartlink: "https://ditto.fm/las_canciones_de_apolo-9ckles",
        produccion: "Producido por 9ckles",
        arte: "Portada de @elinstadeguti",
        tracklist: ["Epanadiplosis", "Romance de la luna", "Las estrellas, mi destino"]
    },
    {
        id: "34",
        titulo: 'Tábata',
        artista: 'MILC',
        img_portada: Tabata,
        ref: 'tabata',
        smartlink: "https://li.sten.to/26ietfow",
        produccion: "Producido por MILC",
        arte: "Portada de @angel.simpatico",
        tracklist: ["Amor Borracho", "La Ventana de mi Cuarto", "Otra Vez", "Ei Mamá", "Futuro", "La Cueva", "Bota Militar", "Meteorito", "Silence Zone", "Plutón (Outro)"]
    },
    {
        id: "33",
        titulo: '3 rios',
        artista: 'krissia',
        img_portada: Rios,
        ref: "3_rios",
        smartlink: "https://ditto.fm/3_rios-krissia_egarae",
        produccion: "producido por egaræ & roc montoriol",
        arte: "portada de uri llobet",
        tracklist: ["3 rios", "Ya mi mama me decía"]
    },
    {
        id: "32",
        titulo: 'Stimolazione della Simulazione',
        artista: 'Darko Morandini',
        img_portada: Stimolazione,
        ref: "stimolazione",
        smartlink: "https://li.sten.to/bnxcmox2",
        produccion: "Producido por Darko Morandini",
        arte: "Portada de @peanutmochi",
        tracklist: ["Issekinichö", "Colomba", "Batticuore", "Buona fortuna", "Buena suerte", "Volpe", "Per caso", "Hasta la vista", "Bambina arrabbiata"]
    },
    {
        id: "31",
        titulo: 'Villancicos Vol. III',
        artista: 'Varios Artistas',
        img_portada: Villancicos3,
        ref: "villancicos3",
        smartlink: "https://ffm.to/villancicos-vol-iii",
        produccion: "Darko Morandini - dani - Colectivo Da Silva - Lashormigas - Ciberchico x Popin'love - Ralphie Choo - daddy san - Perdón - from - Martirio Martirio - Ghouljaboy - 9ckles - J.Skxnny - NO FUCKS - Temerario Mario",
        arte: "Portada de @elinstadeguti",
        tracklist: ["Darko Morandini - Los fieles ¿donde van_", "dani - canción para volver", "Colectivo Da Silva - Vente pal Sur", "Lashormigas - Nuevo Rey", "Ciberchico x Popin'love - Nieva en Valencia", "Ralphie Choo - Copito de Nieve", "daddy san - Especial Nochevieja", "Perdón - Mañana es Navidad", "from - luces de navidad", "Martirio Martirio - (contigo) me gusta que sea navidad", "Ghouljaboy - El Camino Final", "9ckles - navidades en madrid", "J.Skxnny - Campanas", "NO FUCKS - Mi burrito sabanero", "Temerario Mario - Shibum Shibum"]
    },
    {
        id: "30",
        titulo: 'Tren a Madrid',
        artista: 'Daniel Daniel + Ciberchico',
        img_portada: Tren,
        ref: "tren_madrid",
        smartlink: "https://ffm.to/tren-a-madrid",
        produccion: "Producido por Daniel Daniel y Ciberchico",
        arte: "Portada de Daniel Daniel",
        tracklist: ["Tren a Madrid"]
    },
    {
        id: "30",
        titulo: 'CARAOQUE',
        artista: 'jaujeje',
        img_portada: Caraoque,
        ref: "caraoque",
        smartlink: "https://jeannedarc.bandcamp.com/album/caraoque",
        produccion: "",
        arte: "Cover Design by Nicolás Barreto",
        tracklist: [""]
    },
    {
        id: "29",
        titulo: 'Call Center Boy OK',
        artista: 'Toni Poni X',
        img_portada: CallCenter ,
        ref: "call_center",
        smartlink: "https://li.sten.to/buam763u",
        produccion: "Producido por Toni Poni X",
        arte: "Portada de Toni Poni X",
        tracklist: ["HD a veces", "Corazón", "30 días", "Dream Pop", "Aguanieve", "どこに行きたい？", "Al atardecer"]
    },
    {
        id: "28",
        titulo: 'Pregunta',
        artista: 'Toni Poni X',
        img_portada: Pregunta ,
        ref: "pregunta",
        smartlink: "https://soundcloud.com/toniponix/pregunta",
        produccion: "Producido por Toni Poni X",
        arte: "Portada de Toni Poni X",
        tracklist: ["Pregunta"]
    },
    {
        id: "27",
        titulo: 'Prom Fest 2020',
        artista: 'Varios Artistas',
        img_portada: PromFest2020,
        ref: "prom_2020",
        smartlink: "https://li.sten.to/q3pwhv9q",
        produccion: "Carlota - Chavales - Los Sacrificio - Macarena FVO - Menara y Pablo - NO FUCKS - Stephen Please - Yana Zafiro - Antolín - Llorar - Mejores Amigas",
        arte: "Portada de Stephen Please",
        tracklist: ["Carlota: Sé Mi Bebé", "Chavales: ¡Hola, Mi Amor!", "Los Sacrificio: Tú me Dijiste Adiós", "Macarena FVO: Tu Piel Moreno", "Menara y Pablo: Bésame Mucho", "NO FUCKS: Yo lo Que Quiero Es Irme de Fiesta", "Stephen Please: Al Amanecer", "Yana Zafiro: Breaking Free", "Antolín: Lineas", "Llorar: Dos Hombres y un Destion", "Mejores Amigas: Eclipse Total del Amor"]
    },
    {
        id: "26",
        titulo: 'Pole',
        artista: 'daddy san',
        img_portada: Pole,
        ref: "pole",
        smartlink: "https://li.sten.to/gdlr485y",
        produccion: "",
        arte: "",
        tracklist: ["bici mad", "Línea 5", "Romance romántico (mujeres)", "uaaatusojos", "Lisa", "Tarantino"]
    },
    {
        id: "25",
        titulo: 'Lagoas Dream - mix',
        artista: 'Toni Poni X',
        img_portada: LagoasDream,
        ref: "lagoas_dream",
        smartlink: "https://soundcloud.com/toniponix/lagoas-dream-mix",
        produccion: "Producido por Toni Poni X",
        arte: "Portada de Toni Poniiii X",
        tracklist: ["Lagoas Dream - mix"]
    },
    {
        id: "24",
        titulo: 'Canción de Pop de Amor (Remix)',
        artista: 'Daniel Daniel + Cariño',
        img_portada: CancionPop,
        ref: "cancion_pop",
        smartlink: "https://li.sten.to/mtiyq54l",
        produccion: "Producida por Daniel Daniel",
        arte: "Portada de @elinstadeguti",
        tracklist: ["Canción de Pop de Amor (Remix)"]
    },
    {
        id: "23",
        titulo: 'recuerdos un poco antes y un poco después de cuando conocí a un xico (el mio)',
        artista: 'rebe',
        img_portada: RecuerdosXico,
        ref: "recuerdos_xico",
        smartlink: "https://li.sten.to/bi1f9pv6",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Na nananana", "CeloOoOos", "Pienso en ti a todas horas", "Tocame el culo", "Pinxame mosquito", "Mi xico"]
    },
    {
        id: "22",
        titulo: 'Mi xico',
        artista: 'rebe',
        img_portada: MiXico,
        ref: "mi_xico",
        smartlink: "https://li.sten.to/vtkjooyf",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Mi xico"]
    },
    {
        id: "21",
        titulo: 'Abrázame',
        artista: 'Daniel Daniel',
        img_portada: Abrazame,
        ref: "abrazame",
        smartlink: "https://li.sten.to/h919wqgc",
        produccion: "Producida por Daniel Daniel",
        arte: "Portada de Florence Campbell",
        tracklist: ["Abrázame"]
    },
    {
        id: "20",
        titulo: 'Decir adios',
        artista: 'rebe',
        img_portada: DecirAdios,
        ref: "decir_adios",
        smartlink: "https://li.sten.to/zqqmrxhx",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Decir adios"]
    },
    {
        id: "19",
        titulo: 'Prom Fest 2019 Edición Madrid',
        artista: 'Varios Artistas',
        img_portada: PromFest2019_Madrid,
        ref: "prom_2019_madrid",
        smartlink: "https://jeannedarc.bandcamp.com/album/prom-fest",
        produccion: "JUNCAL RIVERO - Confeti de Odio - daniel daniel - Marcelo Criminal - Cabiria - rebe - rebe + daniel daniel (Bonus track)",
        arte: "Portada de Stephen Please",
        tracklist: ["JUNCAL RIVERO - Cumbres Bochornosas", "Confeti de Odio - Hechizo", "daniel daniel - sandy", "Marcelo Criminal - Lo que siento esta vez", "Cabiria - No puedo quitar mis ojos de ti", "rebe - me amaras mañana..¿", "Rebe + Daniel Daniel - Lagrimas en mi colxon"]
    },
    {
        id: "18",
        titulo: 'Villancicos Vol. II',
        artista: 'Varios Artistas',
        img_portada: Villancicos2,
        ref: "villancicos2",
        smartlink: "https://li.sten.to/a8lb7grz",
        produccion: "Daniel Daniel - Menara y Pablo - Yana Zafiro - Marcelo Criminal - Toni Poni X - rebe - Los Austrias - Stephen Please - Cabiria - jaujeje - Miquel y Paco - Juncal Rivero - Chavales",
        arte: "Portada de Alberto Arribas y Miquel C. Bosch",
        tracklist: ["Daniel Daniel - Noche de Paz", "Menara y Pablo - Santa Tell Me", "Yana Zafiro - Villancico Ucraniano", "Marcelo Criminal - Villancico 3", "Toni Poni X - Ángel de Oro", "rebe - Los peces en el río 2", "Los Austrias - Rin Rin", "Stephen Please - All I Want for Xmas", "Cabiria - Tot és un gran arbre de Nadal", "Jaujeje - Nadal Nadal", "Miquel y Paco - Los Campanilleros", "Juncal Rivero - Salsa Rosa", "Chavales - Ya na va se como ante"]
    },
    {
        id: "17",
        titulo: 'Me amaras mañana..¿',
        artista: 'rebe',
        img_portada: MeAmaras,
        ref: "amaras_mañana",
        smartlink: "https://li.sten.to/i86b3ix0",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Me amaras mañana..¿"]
    },
    {
        id: "16",
        titulo: 'Sobrevivire',
        artista: 'rebe',
        img_portada: Sobrevivire,
        ref: "sobrevivire",
        smartlink: "https://li.sten.to/g83zzzet",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Sobrevivire"]
    },
    {
        id: "15",
        titulo: 'Corazon partio',
        artista: 'rebe',
        img_portada: Corazon_partio,
        ref: "corazon_partio",
        smartlink: "https://li.sten.to/fbcqvun8",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Corazon partio"]
    },
    {
        id: "14",
        titulo: 'Fútbol Club',
        artista: 'Daniel Daniel',
        img_portada: FutbolClub,
        ref: "futbol_club",
        smartlink: "https://li.sten.to/p1y8s37f",
        produccion: "Producido por Daniel Daniel",
        arte: "Portada de @elinstadegusti",
        tracklist: ["Las olas", "Mujer Fatal", "Las canciones de Juanita", "New Rules", "Te entiendo", "Totoro", ":P"]
    },
    {
        id: "13",
        titulo: 'Dama Dama',
        artista: 'rebe',
        img_portada: Dama,
        ref: "dama",
        smartlink: "https://li.sten.to/d67fn6bm",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Dama Dama"]
    },
    {
        id: "12",
        titulo: 'Wasap Huerta (Marcelo Criminal)',
        artista: 'rebe',
        img_portada: Wasap,
        ref: "wasap",
        smartlink: "https://li.sten.to/4yqc1i86",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["Wasap Huerta (Marcelo Criminal)"]
    },
    {
        id: "11",
        titulo: 'wapa wapa wapa wapa wapa wapa',
        artista: 'rebe',
        img_portada: Wapa,
        ref: "wapa",
        smartlink: "https://li.sten.to/uvqi26fk",
        produccion: "Producido por rebe",
        arte: "Portada de rebe",
        tracklist: ["wapa wapa wapa wapa wapa wapa"]
    },
    {
        id: "10",
        titulo: 'Directo en mi habitación <3',
        artista: 'rebe',
        img_portada: Directo,
        ref: "directo_habitacion",
        smartlink: "https://li.sten.to/grdblsyz",
        produccion: "Producido por rebe y Juan Azul",
        arte: "Portada de rebe",
        tracklist: ["cuki q me has exo (directo)", "me siento sola (directo)", "dimelo ya (directo)", "corazon partio (directo)"]
    },
    {
        id: "9",
        titulo: 'Producto Final',
        artista: 'Toni Poni X',
        img_portada: Producto,
        ref: "producto",
        smartlink: "https://li.sten.to/qvnxwoyt",
        produccion: "Portada por Toni Poni X",
        arte: "Portada de Toni Poni X",
        tracklist: ["Sol Madrid Luna", "Pop Normal", "Ese Feeling", "Dancehall Mallorquí", "Lluvia de Estrellas", "Otro Mundo", "Bailando", "Desorientá luz", "Baby, the start shine bright", "No está tan mal"]
    },
    {
        id: "8",
        titulo: 'Pincho Tortilla',
        artista: 'Daniel Daniel',
        img_portada: Pincho,
        ref: "pincho_tortilla",
        smartlink: "https://li.sten.to/q3l0nwxv",
        produccion: "Producido por Daniel Daniel",
        arte: "Portada de Daniel Daniel",
        tracklist: ["yo quiero ser tu chico", "macarrones", "me da igual", "una y otra vez", "es especial", ";)", "las de la intuicion", "chica bonita", "si tu quieres mi amor", "q tal stas"]
    },
    {
        id: "7",
        titulo: 'Prom Fest 2019',
        artista: 'Varios Artistas',
        img_portada: PromFest2019_Barcelona,
        ref: "prom_2019_barcelona",
        smartlink: "https://li.sten.to/8etw3iy2",
        produccion: "JUNCAL RIVERO - Confeti de Odio - daniel daniel - Marcelo Criminal - Cabiria - rebe",
        arte: "Cartel de @__muebles__ y Diseño de la portada de Marina Short",
        tracklist: ["JUNCAL RIVERO - Cumbres Bochornosas", "Confeti de Odio - Hechizo", "daniel daniel - sandy", "Marcelo Criminal - Lo que siento esta vez", "Cabiria - No puedo quitar mis ojos de ti", "rebe - me amaras mañana..¿"]
    },
    {
        id: "6",
        titulo: 'recuerdos de cuando me aplastó una roca y me morí',
        artista: 'rebe',
        img_portada: Recuerdos,
        ref: "recuerdos",
        smartlink: "https://li.sten.to/765x37tl",
        produccion: "Producido por rebe",
        arte: "Portada hecha por rebe",
        tracklist: ["no me molestes", "cuki q me has exo", "el futuro", "sabes a piruleta de cereza", "dimelo ya", "ni una sola palabra", "sola en mi habitacion comiendo 1 melocoton", "algo contigo", "me siento sola", "nunca mas nunca mas", "tq cerca de mi", "ramito de violetas"]
    },
    {
        id: "5",
        titulo: 'Villancicos Vol. I',
        artista: 'Varios Artistas',
        img_portada: Villancicos1,
        ref: "villancicos1", 
        smartlink: "https://jeannedarc.bandcamp.com/album/villancicos-3",
        produccion: "AMICS - Cabiria - daniel daniel - Javier, Sara y Miquel - Juncal Rivero - Marcelo Criminal - rebe - Toni Poni X",
        arte: "Portada de Emma Roulette",
        tracklist: ["AMICS - Ara ve Nadal", "Cabiria - La pasada Navidad", "daniel daniel - sotequieroati", "Javier, Sara y Miquel - Villancico por sevillanas", "Juncal Rivero - Adeste Fideles", "Marcelo Criminal - Villancico 2", "rebe - los peces en el rio", "Toni Poni X - Las luces de Navidad"]
    },
    {
        id: "4",
        titulo: 'corazón calzoncillo',
        artista: 'corazón calzoncillo',
        img_portada: corazoncalzoncillo,
        ref: "corazón_calzoncillo", 
        smartlink: "https://jeannedarc.bandcamp.com/album/coraz-n-calzoncillo",
        produccion: "Producido por corazón calzoncillo",
        arte: "Portada de Jordi Rosa",
        tracklist: ["Lucy Pussy", "Mañana", "Calzoncillo", "Bailando Junto A Ti", "Luna Roja", "Ciudad Muerta", "Te Fuiste", "Alcohol", "Madrid"]
    },
    {
        id: "3",
        titulo: 'Grandes Éxitos',
        artista: 'JUNCAL RIVERO',
        img_portada: Grandes,
        ref: "juncal_rivero",
        smartlink: "https://jeannedarc.bandcamp.com/album/juncal-rivero",
        produccion: "Producido por Marco Frías",
        arte: "Portada de Marina Short",
        tracklist: ["Santa Ana", "Águila Azul", "Noche de Reyes", "EL BESO", "and dance", "Tauros"]
    },
    {
        id: "2",
        titulo: 'Scattered Lines // Uncountable Dots',
        artista: 'Bofirax',
        img_portada: Bofirax,
        ref: "bofirax",      
        smartlink: "https://jeannedarc.bandcamp.com/album/scattered-lines-uncountable-dots",
        produccion: "Producido por Bofirax",
        arte: "Portada de Ricard Serarols",
        tracklist: ["Waterfalling", "Where goes the wind", "Meditation", "New Amsterdam", "Storm in the Ocean", "Pointillism", "Gloomy", "Gauean", "Shiny Shadows"]
    }
    ,
    {
        id: "1",
        titulo: 'K7',
        artista: 'Daniel Daniel',
        img_portada: K7,
        ref: "k7",
        smartlink: "https://jeannedarc.bandcamp.com/album/k7",
        produccion: "Producido por Daniel Daniel",
        arte: "Portada de Marina Short",
        tracklist: ["Intro", "Atravesando Dimensiones", "Enemigo", "Luna Llena", "Le Stage De Merde", "Nhombre", "Cada Noche Me Pregunto", "Lejos del Mar", "Me Da Igual", "Hawaii", "Amarte"]
    }
];

export default releases;
