import React, { useEffect, useState } from "react";
import logo from './img/logo_portada_contorno.png';
import soundcloud from './img/soundcloud.png';
import bandcamp from './img/bandcamp.png';
import spotify from './img/spotify_2.png';
import youtube from './img/youtube-logo.png';
import {db} from "./firebase-config";
import './links.css'
import { Link } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore/lite";

function Links () {

    const [enlaces, setEnlaces] = useState([]);
    
    useEffect(() => {
        const getLinks = async () => {
            const data = await getDocs(query(collection(db, "links"), orderBy("id", "desc")));
            setEnlaces(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };
        getLinks();
    }, []);
    
    return (
        <div className="container">
            <Link to='/' className="logo_head">
                <img src={logo} alt="JDA logo" id="logo_links"/>
            </Link>
            <div className="social">
                <a href="https://www.youtube.com/channel/UCSAwpkPv0jK8RKx2fsJbmeQ" target="_blank" className="a-tag" rel= "noopener noreferrer">
                    <img src={youtube} className="imgLinks"   alt="youtube logo"/>
                </a>
                <a href="https://soundcloud.com/jeannedarcamigos" target="_blank" className="a-tag" rel= "noopener noreferrer">
                    <img src={soundcloud} className="imgLinks"   alt="soundcloud logo"/>
                </a>
                <a href="https://jeannedarc.bandcamp.com/" target="_blank" className="a-tag" rel= "noopener noreferrer">
                    <img src={bandcamp} className="imgLinks"   alt="bandcamp logo" id="bandcamp"/>
                </a>
                <a href="https://soundcloud.com/jeannedarcamigos" target="_blank" className="a-tag" rel= "noopener noreferrer">
                    <img src={spotify} className="imgLinks"   alt="spotify logo"/>
                </a>
            </div>
            <div className="linkTree">
                {enlaces.map((link) => (
                    <h4 key={link.id}><a href={link.smartlink} target="_blank" className="a-tag" rel= "noopener noreferrer">{link.titulo}</a></h4>
                ))}
            </div>
        </div>
    )
}

export default Links;