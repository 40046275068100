import React, {useState} from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import './release.css';
import ReleasePage from './ReleasesPage';

function Release (props){  

    const [isShown, setIsShown] = useState(false);
    const release = props.release;

    return (
        <>
            <Link to={`/releases/${props.release.id}`}>
                <div className='item'
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}>
                        <img src={props.release.img_portada} alt={props.release.titulo}  className="image_release" />
                        {isShown && (
                            <div className='info'>
                                <h2 className='headerInfo'>{props.release.titulo} </h2>
                                <h3 className='headerInfo'>{props.release.artista}</h3>
                            </div>
                        )}
                        <div className='mobile_info_release'>
                            <h3 className='mobile_headerInfo'>{props.release.titulo} / {props.release.artista}</h3>
                        </div>
                </div>
            </Link>
            <Switch>
                <Route path="/releases/:id">
                    <ReleasePage release={release}/>
                </Route>
            </Switch>
        </> 
    )
}

export default Release;