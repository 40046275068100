import React from 'react';
import { useParams } from 'react-router';
import lanzamientos from './Lanzamientos';
import Menu from './Menu';
import ReleaseInfo from './ReleaseInfo';
import './releaseinfo.css';

function ReleasePage (props) {

  let params = useParams();
  let releases = lanzamientos;
  
  console.log("Release Page params",params); 
  console.log("Release Page", releases);
  console.log("id", params.id);

  return (
    <>
    <Menu />
    <div className='release_page'>
      {releases.map((release) => {
        return release.id === params.id ?
        <ReleaseInfo release={release} key={release.id}/> : null
      })}
    </div>
    </>
  )
}

export default ReleasePage;