import React from 'react';
import './intro.css';
import { Link } from 'react-router-dom';
import logo_largo from './img/logo_portada_contorno.png';
import 'animate.css';

function Intro() {
  /* const releases = lanzamientos;
  let randomRelease = Math.floor(Math.random()*releases.length); */

  return (
    <div className="container_intro" 
      /* style={{
        backgroundImage: 'url('+releases[randomRelease].img_portada+')',
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: '90%'}} */ >
      <Link to='/releases'>
        <div className='animate__animated container_portada_intro'>
          <img /* src={releases[randomRelease].img_portada} */ src={logo_largo} alt='portada' className='portada_intro' />
        </div>
      </Link>
    </div>
  ) 
}
        
export default Intro