import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from './img/JDA_logo_contorno.png';
import logo_largo from './img/logo_portada_contorno.png';
import './menu.css';

function Menu() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <div className="menu">
        <div className="buton" onClick={handleShow}>
            <img src={logo} alt="JDA logo" width="100px" className="imageButon" />
        </div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <Link to="/" >
                <img src={logo_largo} onClick={handleClose} alt="JDA logo" width="180px" />
              </Link>  
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="contents">
            <Link to="/releases" className="enlace" >
              <h6 className="headerLink" onClick={handleClose}>CATALOGO</h6>  
            </Link>
            {/* <Link to="/merch" className="enlace" >
              <h6 className="headerLink" onClick={handleClose}>MERCH</h6>
            </Link> */}
            <Link to="/links" className="enlace" >
              <h6 className="headerLink" onClick={handleClose}>LINKS</h6>
            </Link>
            <div className="contact">
            <a className="linkContact" href="https://www.instagram.com/jeannedarc.amigos"  target="_blank" rel= "noopener noreferrer">
            <h6 className="headerContact" >@jeannedarc.amigos</h6>
            </a>
            <h6 className="headerContact">hola@jeanne-darc.net</h6> 
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  }
  
  export default Menu;