import React from 'react';
import './releases.css';
import Release from './Release';
import lanzamientos from './Lanzamientos';
import Menu from './Menu';

function Releases (){

    const releases = lanzamientos;

    return (
        <>
        <Menu />
        <div className='releases_page'>
        <div className="releases_page_container">
            {releases.map((release) => (
                <Release key={release.id} release={release} />
            ))}
        </div> 
    </div>
    </>
    )
}

export default Releases;