import React, {useEffect, useState } from 'react';
import { db, auth } from './firebase-config';
import { 
    setDoc,
    doc, 
    getDocs, 
    deleteDoc,
    query, 
    collection, 
    orderBy, 
} from 'firebase/firestore/lite';
import { 
    signInWithEmailAndPassword,
    onAuthStateChanged,
} from 'firebase/auth';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

function Dashboard() {

    const [enlaces, setEnlaces] = useState([]);
    
    const [user, setUser] = useState({});
    const [loginEmail, setLoginEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    // const [signinEmail, setSigninEmail] = useState("")
    // const [signinPassword, setSigninPassword] = useState("")
    const [newLinkId, setNewLinkId] = useState('');
    const [newLinkTitle, setNewLinkTitle] = useState('');
    const [newLinkUrl, setNewLinkUrl] = useState('');
    // const [ updateLinkId, setUpdateLinkId] = useState('');
    // const [ updateLinkTitle, setUpdateLinkTitle] = useState('');
    // const [ updateLinkUrl, setUpdateLinkUrl] = useState('');
    // const [newImg_Portada, setNewImg_Portada] = useState('');
    // const [newCode, setNewCode] = useState('');
    // const [newSmartlink, setNewSmartlink] = useState('');
    // const [newProduccion, setNewProduccion] = useState('');
    // const [newArte, setNewArte] = useState('');
    // const [newTracklist, setNewTracklist] = useState([]);

    // const [open, setOpen] = useState(false);
    
    useEffect(() => {
        const getLinks = async () => {
            const data = await getDocs(query(collection(db, "links"), orderBy("id", "desc")));
            setEnlaces(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };
        getLinks();
    }, []);

    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    })

    /* const provider = new GoogleAuthProvider();

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.log(error.message)
        });
    }; */

    /* const signin = async () => {
        try {
            const user = await createUserWithEmailAndPassword(
                auth, 
                signinEmail, 
                signinPassword
            );
            console.log(user)
        } catch (error) {
            console.log(error.message);
        }
    }; */

    const login = async () => {
        try {
            const user = await signInWithEmailAndPassword(
                auth,
                loginEmail, 
                loginPassword
            );
            console.log(user)
        } catch (error) {
            console.log(error.message)
            alert(error.message)
        }
    };

    const logout = async () => {
        await auth.signOut();
        setUser(null);
    }

    const addLink = async () => {
        await setDoc(doc(db, "links", newLinkId), 
            { 
                id: newLinkId, 
                titulo: newLinkTitle, 
                smartlink: newLinkUrl,
            }).then(function() {
                alert("Enlace añadido correctamente. Recarga la página para ver los cambios.");
              });
    };

    /* const handleClickOpen = (link) => {
        console.log("in handleClickOpen", link, link.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateLink = async (id) => {
        console.log("link id:", id)
        console.log("new title:", updateLinkTitle)
        console.log("new url:", updateLinkUrl)
        const newValues = {
            titulo: updateLinkTitle,
            smartlink: updateLinkUrl,
        }
        console.log("newValues are: ", newValues);
        await updateDoc(doc(db, "links", id), newValues)
            .catch(function(error) {
                console.log(error.message);
            }).then(function() {
            alert("Enlace actualizado correctamente. Recarga la página para ver los cambios.");
        })  
        handleClose();
    }  */

    const deleteLink = async (id) => {
        const linkDoc = doc(db, "links", id);
        await deleteDoc(linkDoc).then(function() {
            alert("Enlace eliminado correctamente. Recarga la página para ver los cambios.")
          });
    }

    const dashboard_container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    }
    const dahboard_panel = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        rowGap: '50px',
        marginTop: '20px',
        marginBottom: '30px',
    }
    const dashboard_panel_header_container = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        height: 'auto',
    }
    const dashboard_panel_header_title = {
        marginBottom: '0px',
        fontWeight: '900',
        color: '#fff',
    }
    const dashboard_panel_new_link_container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        height: 'auto',
        rowGap: '15px',
    }
    const dashboard_panel_new_link_header = {
        marginBottom: '0px',
        fontWeight: '700',
        color: '#fff',
    }
    const dashboard_panel_new_link_form = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        height: 'auto',
        gap: '10px 20px',
    }
    const dashboard_panel_links_preview_container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        rowGap: '20px',
    }
    const dashboard_panel_links_preview_header = {
        marginBottom: '0px',
        fontWeight: '700',
        color: '#fff',
    }
    const dashboard_panel_links_preview_list = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: 'auto',
        rowGap: '30px',
    }
    const dashboard_panel_links_preview_list_item = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        columnGap: '15px',
    }
    const dashboard_panel_links_preview_list_item_header = {
        marginBottom: '0px',
        marginRight: '40px',
    }
    const dashboard_panel_login_container = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',  
        width: '100%',
        height: '100vh',
        rowGap: '25px',
    }
    const dashboard_panel_login_header = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
    }
    const dashboard_panel_login_header_title = {
        marginBottom: '0px',
        fontWeight: '900',
        color: '#fff',
    }
    const dashboard_panel_login_header_subtitle = {
        marginBottom: '0px',
        fontWeight: '900',
        color: '#fff',
    }
    const dashboard_panel_login_form = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        rowGap: '10px',
    }

    return (
        <div style={dashboard_container}>
            {user
                ?   <div style={dahboard_panel}>
                        <div style={dashboard_panel_header_container}>
                            <h3 style={dashboard_panel_header_title}>DASHBOARD</h3>   
                            <button onClick={logout}>Cerrar sesión</button>
                        </div>
                        <div style={dashboard_panel_new_link_container}>
                            <h4 style={dashboard_panel_new_link_header}>AÑADIR ENLACE</h4>
                            <div style={dashboard_panel_new_link_form}>
                                <input style={{width: 50}} placeholder='Id' required={true}
                                    onChange={(e) => setNewLinkId(e.target.value)} />
                                <input style={{flexGrow: '4', width: 300}} placeholder='Titulo' required={true}
                                    onChange={(e) => setNewLinkTitle(e.target.value)} />
                                <input style={{width: 500}} placeholder='URL' required={true}
                                    onChange={(e) => setNewLinkUrl(e.target.value)} />
                                <button onClick={addLink}>Añadir enlace</button>
                            </div>
                        </div>
                        <div style={dashboard_panel_links_preview_container}>
                            <h4 style={dashboard_panel_links_preview_header}>
                                VISTA /links
                                <Link to="/links" target="_blank" rel="noopener noreferrer" >
                                    <OpenInNewIcon style={{marginLeft: '10px'}} />
                                </Link>
                            </h4>
                            <div style={dashboard_panel_links_preview_list}>
                                {enlaces.map((link) => (
                                    <div style={dashboard_panel_links_preview_list_item} key={link.id}>
                                        <h4 style={dashboard_panel_links_preview_list_item_header} key={link.id}>
                                            <a href={link.smartlink} target="_blank" className="a-tag" rel= "noopener noreferrer">
                                                {link.titulo}
                                            </a>
                                        </h4>
                                        {/* <button 
                                            onClick={() => handleClickOpen(link)}>
                                            actualizar enlace
                                        </button>
                                        <Dialog open={open} onClose={handleClose}>
                                            <DialogTitle>Actualizar enlace</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    id="title"
                                                    label="Titulo"
                                                    type="email"
                                                    fullWidth
                                                    variant="standard"
                                                    onChange={(e) => setUpdateLinkTitle(e.target.value)}
                                                />
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    id="url"
                                                    label="URL"
                                                    type="email"
                                                    fullWidth
                                                    variant="standard"
                                                    onChange={(e) => setUpdateLinkUrl(e.target.value)}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Cancelar</Button>
                                                <Button onClick={() => (updateLink(link.id))}>Actualizar</Button>
                                            </DialogActions>
                                        </Dialog> */}
                                        <button onClick={() => (deleteLink(link.id))}>eliminar enlace</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                :   <div style={dashboard_panel_login_container}>
                        <div style={dashboard_panel_login_header}>
                            <h3 style={dashboard_panel_login_header_title}>JEANNE D'ARC DASHBOARD</h3>
                            <h4 style={dashboard_panel_login_header_subtitle}>LOG IN, PLEASE :)</h4>
                        </div>
                        {/* <div className='google_login_form'>
                            <button onClick={signInWithGoogle}>Login with Google</button>
                        </div>
                        <div className='sigin_form'>
                            <input placeholder='Email' onChange={(e) => setSigninEmail(e.target.value)} />
                            <input type='password' placeholder='Password' onChange={(e) => setSigninPassword(e.target.value)} />
                            <button onClick={signin}>Sign In</button>
                        </div> */}
                        <div style={dashboard_panel_login_form}>
                            <input 
                                style={{
                                        width: '300px'
                                    }} 
                                type='text' placeholder='email' required={true}
                                onChange={(e) => setLoginEmail(e.target.value)} />
                            <input 
                                style={{
                                        width: '300px'
                                    }} 
                                type='password' placeholder='password' required={true}
                                onChange={(e) => setLoginPassword(e.target.value)} />
                            <button 
                                style={{
                                        width: '300px'
                                    }} 
                                onClick={login}> Log In </button>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Dashboard;